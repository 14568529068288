.login-container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.verify-container>.login-card {
    max-width: 600px;
}