.header {
    background-color: #9074EA33;
    padding-top: 64px;
    min-height: 75vh;
    user-select: none;
}

.header-bottom {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    background-color: #9074EA33;
    margin-bottom: 48px;
}

.header-content {
    padding: 24px 32px;
    max-width: 1200px;
    margin: auto;
}

.logo {
    height: 56px;
}

.app-name {
    color: #9074EA;
    font-family: 'Montserrat';
    font-weight: 800;
    text-transform: capitalize;
    line-height: 20px;
}

.built-for-shopify {
    display: inline-block;
    margin-top: 20px;
    background-color: #4B3C72;
    color: #F7F7FF;
    border-radius: 24px;
    font-style: italic;
    font-family: 'Montserrat';
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
}

.banner-image {
    display: block;
    margin: auto;
    max-width: 400px;
    width: 80%;
    height: auto;
}

.banner-title {
    margin-top: 12px;
    color: #9074EA;
    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: 800;
    line-height: 36px;
}

.banner-desc {
    max-width: 500px;
    margin-top: 12px;
    color: #4B3C72;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 500;
}

.get-started {
    text-align: center;
    min-width: 200px;
    display: inline-block;
    outline: none;
    margin-top: 48px;
    background-color: #4B3C72;
    color: #F7F7FF;
    padding: 8px 20px;
    border-radius: 24px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none !important;
}

.banner-trial {
    margin-top: 12px;
    color: #4B3C72;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
}

.link {
    color: #4B3C72 !important;
    text-decoration: underline;
}

@media (max-width: 576px) {
    .header-content {
        padding: 0px 32px;
    }

    .banner-image {
        margin-top: 24px;
        width: 100%;
        margin-bottom: 24px;
    }
}