/* Billing Card Styles */
.billing-section {
    max-width: 920px;
    margin: auto;
}

.BillingCards {
    padding: 20px;
    display: flex;
    gap: 20px;

    overflow-x: auto;
    flex-wrap: nowrap;
}

.BillingCard>.Polaris-Box {
    height: 536px;
    min-width: 280px;
    max-width: 320px;

    position: relative;
    padding-bottom: 48px;
}

.BillingCard_header {
    display: flex;
    background-color: var(--p-surface-primary-selected);
    height: 100px;
    justify-content: center;
    text-align: center;
}

.BillingCard_planTitle {
    margin-top: 4px;
    font-size: 18px;
    color: var(--p-action-primary) !important;
    font-weight: 600;
}

.BillingCard_planAmount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    margin-top: 4px;
    font-size: 24px;
    color: var(--p-text) !important;
    font-weight: 600;
}

.BillingCard_planCurrency {
    color: var(--p-text) !important;
    padding-right: 4px;
    font-size: 12px;

    position: absolute;
    left: 0;
    top: 2px;
}

.BillingCard_planDuration {
    color: var(--p-action-primary) !important;
    padding-top: 6px;
    font-size: 10px;
}

.BillingCard_feature_disabled,
.BillingCard_feature {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    line-height: 20px;
}

.BillingCard_feature_disabled {
    color: var(--p-text-subdued);
}

.BillingCard_buttonContainer {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;

    text-align: center;
}

/* Default Style Override */
.Polaris-Icon {
    margin: 0;
    height: 18px;
    margin-right: 8px;
}

.Polaris-Icon--colorPrimary svg {
    fill: #9074EA !important;
}

.billing-button {
    text-align: center;
    display: inline-block;
    outline: none;
    margin-top: 48px;
    background-color: #4B3C72;
    color: #F7F7FF;
    padding: 8px 20px;
    border-radius: 24px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none !important;
}